import React, { useState } from "react"
import styled from "styled-components"
import ProductMob from "../assets/videos/ProductMobile.mp4"
import Productdesk from "../assets/videos/Product Video.mp4"
import Navigation from "../components/Navigation"
import logo from "../assets/images/Infidos.png"
import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import supportImage from "../assets/images/supportdb.png"
import supportImageMob from "../assets/images/product_img.png"

const NavDesktop = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const MobileNavWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const MobileCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width:100%
  height: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: -1;
`

const NavigationMobile = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    padding: 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 150px;
  }
`

const MobileNavigationLinks = styled(Link)`
  display: none;

  @media screen and (max-width: 600px) {

    border-radius: 10%;
    margin-right: 40px;
    margin-left: 40px;
    position: relative; /* Ensure the pseudo-element is positioned relative to the card */
    overflow: hidden; /* Hide the shine effect overflow */
}
    @media screen and (max-width: 600px) {
      display: block;
      text-decoration: none;
      color: rgb(255, 255, 255);
      gap: 70px;
      font-size: 20px;
      padding: 8px;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 24px;
      width: 80%;
      background-color: #f67280;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -75%;
      width: 50%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      transform: skewX(-25deg);
    }

    &:hover:before {
      animation: shine 0.75s ease-in-out;
    }

    @keyframes shine {
      0% {
        left: -75%;
      }
      100% {
        left: 125%;
      }
    }
  }
`
const CloseButton = styled.button`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;

  position: absolute;
  top: 100px;
  right: 20px;
  background-color: white;
  border-radius: 80%;
  color: red;
  border: none;
  width: 24px;
  height: 24px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: #007bff;
  }
`
const ImageContainer = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 600px) {
    display: flex;
  }
`
const MobNavRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.img`
  border-radius: 50%;
  margin: 0px 0px 0px 100px;

  @media screen and (max-width: 600px) {
    border-radius: 50%;
    margin: 20px 0 0 50px;
    width: 70px;
    height: 70px;
  }
`

const LadderBtn = styled.a`
  display: none;
  @media screen and (max-width: 600px) {
    border-radius: 50%;
    margin: 40px 30px 0 0px;
    height: 80px;
    display: flex;
    font-size: 28px;
    color: white;
  }
`

// Product page style

const Backgroundcolor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media screen and (max-width: 600px) {
  }
`

const ProductVideo = styled.video`
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const ProductVideoMoblie = styled.video`
  display: none;
  @media screen and (max-width: 600px) {
    display: Block;
  }
`
const SupportImg = styled.img`
  width: 100%;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const SupportImgMob = styled.img`
  display: none;

  @media screen and (max-width: 600px) {
    width: 100%;

    display: Block;
  }
`

const SupportDbRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BtnServices = styled(Link)`
text-align:center;
text-decoration:none;
padding: 16px;
  color: white;
  background-color: #f67280;
  border: none;
  margin-bottom: 50px;
  margin-top: 50px;

  width: 450px;

  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

      Glassy effect */
  border: 3px solid #fafafa; /* Border similar to the 'a' tag */
  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

 &:before {
    animation: shine 0.75s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }   

  @media screen and (max-width: 600px) {
    width: 100%;
    margin:0;
  border-radius:0;
    margin-bottom: 50px;
  }
`
export default function Products({
  path,
  name,
  path2,
  name2,
  path3,
  name3,
  path4,
  name4,
}) {
  const [mobileNavVisible, setMobileNavVisible] = useState(false)

  const toggleMobileNavVisibility = () => {
    setMobileNavVisible(!mobileNavVisible)
  }

  const handleCloseButtonClick = () => {
    setMobileNavVisible(false)
  }
  return (
    <>
      {!mobileNavVisible && (
        <>
          <Backgroundcolor>
            <NavDesktop>
              <Navigation
                name={"Home"}
                path={"/"}
                name2={"Services"}
                path2={"/services"}
                name3={"Products"}
                path3={"/products"}
                name4={"Blogs"}
                path4={"/blog"}
              />
            </NavDesktop>

            <ImageContainer>
              <MobNavRoot>
                <Logo src={logo} alt="logo" width="100" height="100" />
                <LadderBtn onClick={toggleMobileNavVisibility}>
                  <i className="fa-solid fa-bars larger-icon fa-shake"></i>
                </LadderBtn>
              </MobNavRoot>
            </ImageContainer>

            <ProductVideo loop autoplay="" muted>
              <source src={Productdesk} type="video/mp4" />
              Your browser does not support the video tag.
            </ProductVideo>
            <ProductVideoMoblie loop autoplay="" muted>
              <source src={ProductMob} type="video/mp4" />
              Your browser does not support the video tag.
            </ProductVideoMoblie>
          </Backgroundcolor>
          <SupportDbRoot>
            <SupportImg src={supportImage} alt="support-db-img" />
            <SupportImgMob src={supportImageMob} alt="support-db-img" />
            <BtnServices to="*">Go to the page</BtnServices>
          </SupportDbRoot>

          <Footer />
        </>
      )}

      {mobileNavVisible && (
        <MobileNavWrapper>
          <CloseButton onClick={handleCloseButtonClick}>X</CloseButton>
          <MobileCard />
          <NavigationMobile>
            <MobileNavigationLinks to={path}>{name}</MobileNavigationLinks>
            <MobileNavigationLinks to={path2}>{name2}</MobileNavigationLinks>
            <MobileNavigationLinks to={path3}>{name3}</MobileNavigationLinks>
            <MobileNavigationLinks to={path4}>{name4}</MobileNavigationLinks>
            <MobileNavigationLinks to="*">Discuss Project</MobileNavigationLinks>
          </NavigationMobile>
        </MobileNavWrapper>
      )}
    </>
  )
}
