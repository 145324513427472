import React from "react"
import styled from "styled-components"

import logo from "../assets/images/Infidos.png"
// import startupindia from "../assets/images/Startupindia.png"
import { Link } from "react-router-dom"

const FooterRoot = styled.footer`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin-top: 200px;
  @media screen and (max-width: 600px) {
    height: 60vh;
  }
`

const MainFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 100px;
  margin-bottom: 60px;
  margin-top: 80px;
  width: 80%;
  @media screen and (max-width: 600px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    margin-bottom: 60px;
    margin-top: 300px;
    gap: 20px;
  }
`
const LearnContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 60px;
  color: white;
  font-size: 18px;
  width: 200px;
  width: 80%;
  @media screen and (max-width: 600px) {
    margin: 0;
    font-size: 20px;
    width: 80%;
  }
`
const MobFooterRoot = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 20px;
  color: white;
  font-size: 18px;
  width: 60%;
  @media screen and (max-width: 600px) {
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 70px;
    font-size: 20px;
    width: 80%;
  }
`
// const LearnContainerLink = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
//   margin-left: 60px;
//   color: white;
//   font-size: 18px;
//   width: 140px;
//      @media screen and (max-width: 600px) {
// margin:0;
// display:none;
// `
// const BlogContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
//   margin-left: 60px;
//   color: white;
//   font-size: 18px;
//   width: 20%;
//   @media screen and (max-width: 600px) {
//     margin: 0;
//     display: none;
//   }
// `
const LearnImage = styled.img`
  border-radius: 50%;
  margin-left: 5px;
  @media screen and (max-width: 600px) {
    width: 70px;
    height: 70px;
    margin-top: 50px;
  }
`
const StartupTile = styled.img`
  margin-top: 20px;
  width: 80%;
  height: 46px;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    width: 124%;
    height: 50%;
    border-radius: 10px;
    margin-top: 20px;
  }
`
const FooterSubhead = styled.p`
  font-size: 16px;
  margin-top: 10px;
`
const HomeLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-family: Arial;
  font-weight: 100;
  width: 100%;
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-weight: 700;
    font-size: 12px;
    width: 100%;
  }
`
const AddressLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-top: 20px;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: center;
  border: none;
  color: white;
  font-size: 16px;
  background-color: #f67280;
  width: 200px;
  border-radius: 24px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  /* Glassy effect */

  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
    animation: shine 5s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 600px) {
    font-weight: 700;
    font-size: 9px;
    width: 120%;
  }
`

const FooterAddress = styled.div`
  gap: 0px;
`

const CompanyAddress = styled.div`
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  width: 250px;
  padding: 10px;

  @media screen and (max-width: 600px) {
    font-weight: 700;
    font-size: 10px;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    width: 50%;
  }
`

const AddressPara = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 7px;
  width: 100%;
`

const LogoFooter = styled.div`
  margin-left: 30px;
`

const SocialmediaLinks = styled.div`
  margin-bottom: 80px;
  margin-left: 220px;
  @media screen and (max-width: 600px) {
    margin: 0;
    margin-left: 40px;
    width: 90%;
  }
`

const LargeIcon = styled(Link)`
  font-size: 32px;
  margin-left: 6px;
  color: white;
  @media screen and (max-width: 600px) {
    text-align: center;
    font-size: 22px;
  }
`

const Copyright = styled.p`
  width: 90%;
  margin-top: 14px;
  margin-left: 10px;
  color: white;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 14px;
  }
`

export default function Footer() {
  return (
    <>
      <FooterRoot>
        <MainFooterContainer>
          <MobFooterRoot>
            <LearnContainer1>
              <FooterSubhead>Company</FooterSubhead>
              <HomeLink to="/">Home</HomeLink>
              <HomeLink to="*">Contact Us</HomeLink>
              <HomeLink to="*">Privacy Policy</HomeLink>
              <HomeLink to="*">Terms and Conditions</HomeLink>
            </LearnContainer1>

            <LearnContainer1>
              <FooterSubhead>Product</FooterSubhead>
              <HomeLink to="/products">Sen2sql</HomeLink>
              <HomeLink to="/products">Indlang</HomeLink>
            </LearnContainer1>
          </MobFooterRoot>
          <MobFooterRoot>
            <LearnContainer1>
              <FooterSubhead>Services</FooterSubhead>
              <HomeLink to="/services/erp">ERP Systems</HomeLink>
              <HomeLink to="/services/webdevelopement">Web Devlopement</HomeLink>
              <HomeLink to="/services/mobiledevelopement">
                Mobile Devlopement
              </HomeLink>{" "}
              <HomeLink to="/services/ui">UI/UX Design</HomeLink>
            </LearnContainer1>
            <LearnContainer1>
              <FooterSubhead>Resources</FooterSubhead>
              <HomeLink to="/blog">Blogs</HomeLink>
            </LearnContainer1>
          </MobFooterRoot>
          <LearnContainer1>
            <FooterAddress>
              <LogoFooter>
                <LearnImage src={logo} alt="logo" width="120" height="120" />

                <CompanyAddress>
                  <AddressPara> LLPIN: ACB-0615</AddressPara>
                  <AddressPara>Arisipalayam,Salem,</AddressPara>
                  <AddressPara>TamilNadu-636009.</AddressPara>
                  <AddressPara>India.</AddressPara>
                </CompanyAddress>
                <AddressLink
                  to="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJvlHtlKkNCdkFRtWvfZpgsSdlRvXTFZTLjvXXTWRpdVshbWQgGrgZcrgrSzmlLMtCNJVZg"
                  target="_blank"
                >
                  info@infidos.com
                </AddressLink>
                {/* <StartupTile src={startupindia} alt="logo" width="270" height="60" /> */}
              </LogoFooter>
            </FooterAddress>
          </LearnContainer1>

          {/* <BlogContainer>
            <FooterSubhead>Blogs</FooterSubhead>
            <HomeLink>
              Mastering Dates in SQL: Queries, Formats, and Functions Explained
            </HomeLink>
            <HomeLink href="index.html">
              Kickstart Your Data Career Today: Master SQL
            </HomeLink>
            <HomeLink href="index.html">
              Tackling SQL NULL Values: A Step-by-Step Guide for Optimised Data
              Analysis
            </HomeLink>
            <HomeLink HomeLink href="index.html">
              How do you join multiple tables in SQL?
            </HomeLink>
          </BlogContainer> */}
        </MainFooterContainer>

        <SocialmediaLinks>
          <LargeIcon
            to="https://www.linkedin.com/company/infidosllp/"
            target="_blank"
          >
            <i class="fa-brands fa-linkedin fa-bounce larger-icon"></i>
          </LargeIcon>
          <LargeIcon to="/blank">
            <i class="fa-solid fa-square-envelope fa-bounce larger-icon"></i>
          </LargeIcon>
          <LargeIcon to="https://www.instagram.com/">
            <i class="fa-brands fa-square-instagram fa-bounce larger-icon"></i>
          </LargeIcon>
          <LargeIcon to="https://www.youtube.com/">
            <i class="fa-brands fa-square-youtube fa-bounce larger-icon"> </i>
          </LargeIcon>
          <LargeIcon to="https://www.youtube.com/">
            <i class="fa-brands fa-github fa-bounce larger-icon"></i>
          </LargeIcon>
          <Copyright>&copy; 2024 INFIDOS LLP</Copyright>
        </SocialmediaLinks>
      </FooterRoot>
    </>
  )
}
