import React, { useState } from "react"
import styled from "styled-components"
// import Webdev from "../assets/images/service page.png"
// import ErpContent4 from "../assets/images/ERP_Content (4).png"
// import Webdevelop from "../assets/images/webdevelop.png"
import { Link } from "react-router-dom"
import logo from "../assets/images/Infidos.png"
// import webcontent from "../assets/images/42.png"
// import webcontent2 from "../assets/images/43.png"
// import webcontent3 from "../assets/images/44.png"
// import webcontent4 from "../assets/images/45.png"
// import webcontent5 from "../assets/images/46.png"
// import webcontent6 from "../assets/images/47.png"

// import sales from "../assets/images/sales-.png"
// import industry from "../assets/images/indursty.png"
// import inventory from "../assets/images/inventory-.png"
// import accounting from "../assets/images/accounting .png"
// import purchase from "../assets/images/purchase.png"
// import rapid from "../assets/images/Rapid.png"
// import support from "../assets/images/user_support.png"
// import time from "../assets/images/24_7.png"
// import cost from "../assets/images/cost.png"
// import secure from "../assets/images/secure.png"
// import star from "../assets/images/star.png"
// import InfidosArrow from "../assets/videos/infidos_arrow.mp4"
// import IntroImage1 from "../assets/images/introImage.png"
// import IntroImageLap from "../assets/images/IntroImage2.png"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Erp from "../services/Erp"
import Webdevelopement from "../services/Webdevlopement"
import MobileDevelopement from "../services/MobileDevelopment"
import Ui from "../services/Ui"

const NavDesktop = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const MobileNavWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const MobileCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width:100%
  height: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: -1;
`

const NavigationMobile = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    padding: 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 150px;
  }
`

const MobileNavigationLinks = styled(Link)`
  display: none;

  @media screen and (max-width: 600px) {
   
    border-radius: 10%;
    margin-right: 40px;
    margin-left: 40px;
    position: relative; /* Ensure the pseudo-element is positioned relative to the card */
    overflow: hidden; /* Hide the shine effect overflow */
}
    @media screen and (max-width: 600px) {
      display: block;
      text-decoration: none;
      color: rgb(255, 255, 255);
      gap: 70px;
      font-size: 20px;
      padding: 8px;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 24px;
      width: 80%;
      background-color: #f67280;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -75%;
      width: 50%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      transform: skewX(-25deg);
    }

    &:hover:before {
      animation: shine 0.75s ease-in-out;
    }

    @keyframes shine {
      0% {
        left: -75%;
      }
      100% {
        left: 125%;
      }
    }
  }
`
const CloseButton = styled.button`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;

    position: absolute;
    top: 100px;
    right: 20px;
    background-color: white;
    border-radius: 80%;
    color: red;
    border: none;
    width: 24px;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #007bff;
    }
  }
`
const ImageContainer = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 600px) {
    display: flex;
  }
`
const MobNavRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.img`
  border-radius: 50%;
  margin: 0px 0px 0px 100px;

  @media screen and (max-width: 600px) {
    border-radius: 50%;
    margin: 20px 0 0 50px;
    width: 70px;
    height: 70px;
  }
`

const LadderBtn = styled.a`
  display: none;
  @media screen and (max-width: 600px) {
    border-radius: 50%;
    margin: 40px 30px 0 0px;
    height: 80px;
    display: flex;
    font-size: 28px;
    color: white;
  }
`
// Srevices Style Starts
// const ErpMainRoot = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-top: 100px;
//   @media screen and (max-width: 600px) {
//     margin-top: 30px;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//   }
// `

// const ErpIntroContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//     width: 90%;
//     align-items: center;
//     justify-content: center;
//     margin-left: 20px;
//   }
// `
// const ErpIntroContainer2 = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   gap: 40px;
//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
//     margin-left: 20px;
//     width: 90%;
//   }
// `

// const ErpIntroSubContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 67%;
//   gap: 5px;
//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     align-items: center;
//     justify-content: center;
//   }
// `

// const ErpTitleContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 28px;
//   color: white;
//   width: 67%;
//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     font-size: 14px;
//     gap: 10px;
//   }
// `

// const ErpTitle = styled.h1`
//   @media screen and (max-width: 600px) {
//     width: 90%;
//     text-align: center;
//   }
// `
// const Logo1 = styled.img`
//   border-radius: 50%;
//   margin: 0px 0px 0px 100px;

//   @media screen and (max-width: 600px) {
//     display: none;
//   }
// `

// const ErpPara = styled.p`
//   color: white;
//   width: 63%;
//   font-weight: 250;
//   font-size: 24px;
//   @media screen and (max-width: 600px) {
//     font-size: 16px;
//     width: 100%;
//   }
// `
// const ErpPara2 = styled.p`
//   color: white;
//   width: 50%;
//   font-weight: 200;
//   font-size: 22px;
//   @media screen and (max-width: 600px) {
//     font-size: 16px;
//     width: 100%;
//   }
// `

// const IntroImage = styled.img`
//   width: 24%;
//   height: 24%;
//   @media screen and (max-width: 600px) {
//     width: 70%;
//     height: 70%;
//   }
// `
// const IntroImage2 = styled.img`
//   width: 26%;
//   height: 26%;
//   @media screen and (max-width: 600px) {
//     display: none;
//   }
// `
// const IntroImageMob = styled.img`
//   display: none;

//   @media screen and (max-width: 600px) {
//     display: block;
//     width: 70%;
//     height: 70%;
//   }
// `

// const ErpKeyPointRoot = styled.div`
//   width: 100%;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding-top: 100px;

//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//   }
// `
// const ErpKeyPointRoot2 = styled.div`
//   width: 100%;
//   color: white;
//   display: flex;
//   align-items: center;
//   padding-top: 100px;
//   padding-bottom: 100px;
//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//   }
// `
// const ErpFeatures = styled.div`
//   width: 100%;
//   color: white;
//   flex-direction: column;
//   display: flex;
//   align-items: center;
//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//   }
// `

// const ErpQuote = styled.p`
//   text-align: center;
//   color: white;
//   padding-top: 100px;
//   background-color: #2c3e76;
// `
// const ErpQuote2 = styled.p`
//   text-align: center;
//   color: #ffe1af;
//   padding-top: 100px;
//   background-color: #2c3e76;
// `

// const KeyPointContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//   }
// `
// const KeyPointContainer2 = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background-color: #2c3e76;
//   width: 96%;
//   padding-left: 50px;

//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//     padding-left: 0px;
//   }
// `
// const KeyPointTitle = styled.h3`
//   background-color: #2c3e76;

//   & ul {
//     margin: 30px;
//     font-size: 14px;
//     background-color: #2c3e76;
//   }
//   & li {
//     background-color: #2c3e76;
//   }
// `

// const KeyPointTitle2 = styled.h3`
//   background-color: #2c3e76;
//   display: flex;
//   align-items: center;
//   justify-content: start;
//   color: #ffe1af;
//   @media screen and (max-width: 600px) {
//     display: flex;
//     flex-direction: column;
//     padding-top:20px;
//   }
// `
// const ListItems = styled.div`
//   background-color: #2c3e76;
//   & ul {
//     margin: 30px;

//     font-size: 14px;
//     background-color: #2c3e76;
//   }
//   & li {
//     background-color: #2c3e76;
//   }
// `
// const SaleImg = styled.img`
//   width: 20%;
//   height: 20%;
//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     width: 70%;
//     height: 70%;
//   }
// `

// const SaleImg2 = styled.img`
//   width: 10%;
//   height: 10%;
//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     width: 50%;
//     height: 50%;
//   }
// `

// const InfidosArrowVideo = styled.video`
//   width: 150px;
//   position: absolute;
//   margin-bottom: 400px;
//   margin-right: 650px;
//   @media screen and (max-width: 600px) {
//   display:none;
//   }
// `
// const ErpContent = styled.img`
//   width: 98%;
//   margin-bottom: 70px;
//   background-color: #2c3e76;

//   @media screen and (max-width: 600px) {
//     display: none;
//   }
// `
// const ContentContainer = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// `
// const LastPageTitle = styled.div`
//   color: white;
//   display: flex;
//   flex-direction: column;
//   align-items: end;
//   background-color: #2c3e76;
//   padding-bottom: 25px;

//   & p {
//     background-color: #2c3e76;

//     padding-right: 25px;
//   }
// `

// const ErpBenefits = styled.div`
//   color: white;
//   background-color: #2c3e76;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   padding-bottom: 80px;
//   align-items: center;
//   justify-content: center;
//   & p {
//     width: 80%;
//     font-size: 16px;
//     background-color: #2c3e76;
//     padding-bottom: 5px;
//     text-align: left;
//     @media screen and (max-width: 600px) {
//       font-size: 14px;
//       width: 90%;
//     }
//   }
// `
// const SpacePara = styled.p`
//   margin-top: 30px;
//   background-color: #2c3e76;
// `
// const WebdevelopementRoot = styled.div`
//   display: none;
//   @media screen and (max-width: 600px) {
//     font-size: 14px;
//     color: white;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     background-color: #2c3e76;
//   }
// `
// const WebSubroot = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: #2c3e76;
// `
// const Img = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   padding-top: 30px;
//   background-color: #2c3e76;
// `
// const WebdevelopeImg = styled.img`
//   width: 60%;
//   height: 60%;
//   background-color: #2c3e76;
// `

// const WebTitle = styled.h1`
//   width: 90%;
//   padding-bottom: 20px;
//   background-color: #2c3e76;
// `
// const WebContent1 = styled.p`
//   width: 90%;
//   font-size: 15px;
//   background-color: #2c3e76;
// `

// const WebLanguages = styled.div`
//   width: 90%;
//   background-color: #2c3e76;

//   padding-top: 50px;
//   & p {
//     background-color: #2c3e76;

//     font-size: 15px;
//   }
// `
// const Allcontent = styled.div`
//   background-color: #2c3e76;
// `
export default function Services({
  path,
  name,
  path2,
  name2,
  path3,
  name3,
  path4,
  name4,
}) {
  const [mobileNavVisible, setMobileNavVisible] = useState(false)

  const toggleMobileNavVisibility = () => {
    setMobileNavVisible(!mobileNavVisible)
  }

  const handleCloseButtonClick = () => {
    setMobileNavVisible(false)
  }
  return (
    <>
      {!mobileNavVisible && (
        <>
          <NavDesktop>
            <Navigation
              name={"Home"}
              path={"/"}
              name2={"Services"}
              path2={"/services"}
              name3={"Products"}
              path3={"/products"}
              name4={"Blogs"}
              path4={"/blog"}
            />
          </NavDesktop>
          <ImageContainer>
            <MobNavRoot>
              <Logo src={logo} alt="logo" width="100" height="100" />
              <LadderBtn onClick={toggleMobileNavVisibility}>
                <i className="fa-solid fa-bars larger-icon fa-shake"></i>
              </LadderBtn>
            </MobNavRoot>
          </ImageContainer>
          {/* <ContentContainer>
            <ErpMainRoot>
              <ErpIntroContainer>
                <ErpIntroSubContainer>
                  <ErpTitleContainer>
                    <ErpTitle> ERP system</ErpTitle>
                    <Logo1 src={logo} alt="logo" width="100" height="100" />
                  </ErpTitleContainer>
                  <ErpPara>
                    Implementation of the Enterprise Resource Planning (ERP) in
                    Business Environment.
                  </ErpPara>
                </ErpIntroSubContainer>
                <IntroImage src={IntroImage1} alt="logo" width="100" height="100" />
              </ErpIntroContainer>

              <ErpIntroContainer2>
                <IntroImage2
                  src={IntroImageLap}
                  alt="logo"
                  width="100"
                  height="100"
                />
                <ErpPara2>
                  Enterprise Resource Planning (ERP) systems are integrated software
                  platforms that manage a company's core business processes,
                  including finance, HR, manufacturing, supply chain, services,
                  procurement, and others.
                </ErpPara2>
                <IntroImageMob
                  src={IntroImageLap}
                  alt="logo"
                  width="100"
                  height="100"
                />
              </ErpIntroContainer2>
            </ErpMainRoot>
            <ErpQuote>
              “Discover the Future of Business with INFIDOS ERP Solutions”
            </ErpQuote>

            <ErpKeyPointRoot>
              <div>
                <KeyPointContainer>
                  <SaleImg src={sales} alt="logo" width="100" height="100" />
                  <KeyPointTitle>
                    Sales :
                    <ul>
                      <li>Sales Order Management</li>
                      <li>Sales Analysis</li>
                      <li>Product Management</li>
                      <li> Shipping Management</li>
                      <li> Product Variant Management</li>
                    </ul>
                  </KeyPointTitle>
                </KeyPointContainer>

                <KeyPointContainer>
                  <SaleImg src={inventory} alt="logo" width="100" height="100" />
                  <KeyPointTitle>
                    Inventory :
                    <ul>
                      <li> Stock Management </li>
                      <li> Scrap Management </li>
                      <li> Reordering Rule </li>
                      <li> Inventory Adjustments</li>
                      <li>Warehouse Analysis </li>
                      <li> Product Expiry Management</li>
                      <li> Backorder Management </li>
                      <li> Restrict Product Purchase</li>
                      <li> Stock Valuation</li>
                      <li> Stock Analysis</li>
                      <li> Putaway Rules</li>
                      <li> Location Management </li>
                      <li>Removal Strategy </li>
                      <li>Multi-Step Routing</li>
                    </ul>
                  </KeyPointTitle>
                </KeyPointContainer>
              </div>
              <div>
                <KeyPointContainer>
                  <SaleImg src={industry} alt="logo" width="100" height="100" />
                  <KeyPointTitle>
                    Manufacturing :
                    <ul>
                      <li> Work Order Management </li>
                      <li> Workcenter Management </li>
                      <li> BoM (Bill of Materials) Management </li>
                      <li> Subcontracting </li>
                      <li> Security Lead Time </li>
                      <li> Work Order Analysis </li>
                      <li> Manufacture Order Analysis</li>
                    </ul>
                  </KeyPointTitle>
                </KeyPointContainer>
                <KeyPointContainer>
                  <SaleImg src={accounting} alt="logo" width="100" height="100" />
                  <KeyPointTitle>
                    Accounting :
                    <ul>
                      <li> Accounting Dashboard</li>
                      <li>General Ledger</li>
                      <li>Partner Ledger</li>
                      <li> Tax Reports</li>
                      <li> Analytic Reports</li>
                      <li>Bill Management</li>
                    </ul>
                  </KeyPointTitle>
                </KeyPointContainer>{" "}
                <KeyPointContainer>
                  <SaleImg src={purchase} alt="logo" width="100" height="100" />
                  <KeyPointTitle>
                    Purchase :
                    <ul>
                      <li>Purchase Order Management</li>
                      <li>Vendor Management</li>
                      <li>Vendor Pricelist Management</li>
                      <li> Control Policy </li>
                    </ul>
                  </KeyPointTitle>
                  <InfidosArrowVideo loop autoplay="" muted>
                    <source src={InfidosArrow} type="video/mp4" />
                    Your browser does not support the video tag.
                  </InfidosArrowVideo>
                </KeyPointContainer>
              </div>
            </ErpKeyPointRoot>

            <ErpQuote2>
              Features of INFIDOS ERP, incorporating user-friendly customization,
              24/7 server stability, and cost-effectiveness:
            </ErpQuote2>

            <ErpKeyPointRoot2>
              <ErpFeatures>
                <KeyPointContainer2>
                  <KeyPointTitle2>
                    <SaleImg2 src={time} alt="logo" width="100" height="100" />
                    Server Stability :
                  </KeyPointTitle2>
                  <ListItems>
                    <ul>
                      <li>
                        A robust, redundant server infrastructure with 99.9% uptime
                        guaranteed.
                      </li>
                      <li>
                        Automated monitoring and proactive issue resolution to
                        minimize downtime.
                      </li>
                      <li>
                        Scalable architecture to handle increasing workloads without
                        performance degradation.
                      </li>
                    </ul>
                  </ListItems>
                </KeyPointContainer2>

                <KeyPointContainer2>
                  <KeyPointTitle2>
                    <SaleImg2 src={rapid} alt="logo" width="100" height="100" />
                    Rapid Implementation :
                  </KeyPointTitle2>
                  <ListItems>
                    <ul>
                      <li>
                        Quick and straightforward implementation process, reducing
                        downtime and disruption.
                      </li>
                      <li>
                        {" "}
                        Pre-configured templates for common industry-specific
                        processes.
                      </li>
                      <li>
                        {" "}
                        Seamless data migration and integration capabilities for
                        existing systems.
                      </li>
                    </ul>
                  </ListItems>
                </KeyPointContainer2>

                <KeyPointContainer2>
                  <KeyPointTitle2>
                    <SaleImg2 src={support} alt="logo" width="100" height="100" />
                    User Support and Training :
                  </KeyPointTitle2>
                  <ListItems>
                    <ul>
                      <li>
                        {" "}
                        Comprehensive onboarding and training resources for users.
                      </li>
                      <li>
                        {" "}
                        12/7 customer support and assistance for any technical
                        issues.
                      </li>
                      <li>
                        {" "}
                        Regular updates and new feature releases to enhance user
                        experience.
                      </li>
                    </ul>
                  </ListItems>
                </KeyPointContainer2>
              </ErpFeatures>
              <ErpFeatures>
                <KeyPointContainer2>
                  <KeyPointTitle2>
                    <SaleImg2 src={cost} alt="logo" width="100" height="100" />
                    Cost-Effective Solution:
                  </KeyPointTitle2>
                  <ListItems>
                    <ul>
                      <li>
                        A robust, redundant server infrastructure with 99.9% uptime
                        guaranteed.
                      </li>
                      <li>
                        Automated monitoring and proactive issue resolution to
                        minimize downtime.
                      </li>
                      <li>
                        Scalable architecture to handle increasing workloads without
                        performance degradation.
                      </li>
                    </ul>
                  </ListItems>
                </KeyPointContainer2>
                <KeyPointContainer2>
                  <KeyPointTitle2>
                    <SaleImg2 src={secure} alt="logo" width="100" height="100" />
                    Data Security:
                  </KeyPointTitle2>
                  <ListItems>
                    <ul>
                      <li> Robust data encryption and access control mechanisms.</li>
                      <li>
                        {" "}
                        Automated backups and disaster recovery plans to ensure data
                        integrity.
                      </li>
                      <li>
                        {" "}
                        Compliance with industry standards and regulations to protect
                        sensitive information.
                      </li>
                    </ul>
                  </ListItems>
                </KeyPointContainer2>
              </ErpFeatures>
            </ErpKeyPointRoot2>
            {/* 
            <ErpContent src={ErpContent1} alt="erp" /> */}
          {/* <ErpContent src={ErpContent2} alt="erp" /> */}
          {/* <ErpContent src={ErpContent4} alt="erp" /> */}
          {/* <LastPageTitle>
              <p>Wake up ! We are with you!</p>
              <p>@INFIDOS .LLP</p>
            </LastPageTitle>

            <ErpBenefits>
              <p>Unleash the Power of ERP with Our Exclusive Offer!</p>
              <p>
                Are you ready to elevate your business with the full potential of ERP
                solutions?
              </p>
              <p>Look no further!</p>
              <SpacePara>
                We're offering the World’s No.1 ERP Sofware at a low price with Lot’s
                customization based on your business needs.{" "}
              </SpacePara>
              <SpacePara>benefits of ERP firsthand with no upfront costs.</SpacePara>
              <SpacePara>
                But that's not all! Avail our unmatched services at a budget-friendly
                rate:
              </SpacePara>
              <SpacePara>
                ✨ Server Setup: Leave the technicalities to us! Our experts will
                seamlessly set up your servers to ensure optimal ERP performance.
              </SpacePara>
              <p>
                ✨ Installation: Hassle-free installation process to get your ERP up
                and running swiftly. We handle the details, so you can focus on what
                matters most – your business.
              </p>
              <p>
                ✨ Annual Maintenance: Enjoy peace of mind with our affordable annual
                maintenance plans. We're committed to keeping your ERP system running
                smoothly year-round.
              </p>
              <p>Why choose us?</p>
              <p>
                🚀 Expertise: Our team of seasoned professionals brings years of
                experience in ERP solutions.
              </p>
              <p>
                🌐 Reliability: Trust in a reliable partner for your ERP journey –
                from POC to ongoing maintenance.
              </p>
              <p>
                💰 Cost-Effective: Unbeatable prices for server setup, installation,
                and annual maintenance, ensuring you get maximum value for your
                investment.
              </p>
              <SpacePara>
                Don't miss out on this incredible opportunity to streamline your
                business processes and boost productivity. Contact us today to unlock
                the full potential of ERP!
              </SpacePara>
            </ErpBenefits> */}
          {/* </ContentContainer>  */}
          <Erp />
          <Webdevelopement />
          <MobileDevelopement />
          <Ui />
          {/* 
           <WebdevelopementRoot>
            <WebSubroot>
              <WebTitle>Web Development</WebTitle>
              <WebContent1>
                Our seasoned web development team can architect and build robust,
                scalable web applications tailored to your business needs. We
                specialize in modern frameworks and technologies like:
              </WebContent1>

              <WebLanguages>
                <p>Front-end: React, Next.js</p>
                <p>Back-end: Node.js/Express.js, Python/Fast API/ Flask/Django. </p>
                <p>Databases: MongoDB, MySQL</p>
              </WebLanguages>
            </WebSubroot>
            <Img>
              <WebdevelopeImg src={Webdevelop} alt="erp" />
            </Img>
          </WebdevelopementRoot> */}
          {/* <Allcontent> */}
          {/* <ErpContent src={Webdev} alt="erp" />
            <ErpContent src={webcontent} alt="erp" />
            <ErpContent src={webcontent2} alt="erp" />{" "}
            <ErpContent src={webcontent3} alt="erp" />
            <ErpContent src={webcontent4} alt="erp" />{" "}
            <ErpContent src={webcontent5} alt="erp" /> */}
          {/* <ErpContent src={webcontent6} alt="erp" /> */}
          {/* </Allcontent>  */}
          <Footer />
        </>
      )}

      {mobileNavVisible && (
        <MobileNavWrapper>
          <CloseButton onClick={handleCloseButtonClick}>X</CloseButton>
          <MobileCard />
          <NavigationMobile>
            <MobileNavigationLinks to={path}>{name}</MobileNavigationLinks>
            <MobileNavigationLinks to={path2}>{name2}</MobileNavigationLinks>
            <MobileNavigationLinks to={path3}>{name3}</MobileNavigationLinks>
            <MobileNavigationLinks to={path4}>{name4}</MobileNavigationLinks>
            <MobileNavigationLinks to="*">Discuss Project</MobileNavigationLinks>
          </NavigationMobile>
        </MobileNavWrapper>
      )}
    </>
  )
}
