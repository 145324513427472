import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import uxicon from "../assets/images/Ux.png"
const Backbtn = styled.button`
text-align:center;
text-decoration:none;
font-size:20px;
font-weight:2000;
padding: 10px;
margin-top:20px;
margin-right:40px;
border-radius:50%;
  color: white;
  background-color: #f67280;
  border: none;
  width: 50px;
  height:50px;

  // border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

      Glassy effect */
  border: 3px solid #fafafa; /* Border similar to the 'a' tag */
  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

 &:before {
    animation: shine 0.75s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }   

  @media screen and (max-width: 600px) {
    width: 14%;
  margin:20px;
  }
`
const BtnRoot = styled.div`
  display: flex;
  justify-content: end;
  background-color: #2c3e76;
`
const Mobiledevelopement = styled.div`
  width: 100%;
  padding-top: 100px;
  background-color: #2c3e76;
`

const MobileSubdevelopement = styled.div`
  width: 78%;
  display: flex;
  background-color: #2c3e76;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`

const Contentcontainer = styled.div`
  background-color: #2c3e76;

  color: white;
  width: 90%;

  & p {
    width: 90%;
    font-size: 18px;
    padding-bottom: 5px;
    background-color: #2c3e76;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
      width: 88%;
      font-size: 18px;
      padding-bottom: 5px;
      background-color: #2c3e76;
    }
  }
`
const MobiledevTitle = styled.h1`
  background-color: #2c3e76;
  padding-bottom: 30px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
`
const WebLanguages = styled.div`
  background-color: #2c3e76;
  width: 40%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  & p {
    background-color: #2c3e76;
    font-size: 16px;
    padding: 5px;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    background-color: #2c3e76;
    margin: 0px;
    padding-top: 30px;
    & p {
      background-color: #2c3e76;
      font-size: 15px;
    }
  }
`
const UXImage = styled.img`
  background-color: #2c3e76;
  width: 280px;
  height: 250px;
`
const ImageContainer = styled.div`
  display: flex;
  gap:300px;
  background-color: #2c3e76;
  padding-bottom:100px;
  @media screen and (max-width: 600px) {
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:40px;
`
export default function Ui() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBackClick = () => {
    navigate("/services")
  }

  const isServicesPage = location.pathname === "/services"
  return (
    <>
      {!isServicesPage && (
        <BtnRoot>
          <Backbtn onClick={handleBackClick}>&#8592; </Backbtn>
        </BtnRoot>
      )}
      <Mobiledevelopement>
        <MobileSubdevelopement>
          <Contentcontainer>
            <MobiledevTitle> UI/UX Design</MobiledevTitle>

            <p>
              Through user-centered design thinking, we craft intuitive digital
              experiences
            </p>
            <p>that drive engagement and conversions.</p>
            <p>Our UI/UX design process:</p>
          </Contentcontainer>
        </MobileSubdevelopement>
        <ImageContainer>
          <WebLanguages>
            <ul>
              <li>
                {" "}
                <p> User research & persona definition</p>
              </li>
              <li>
                <p>Information architecture & user flows</p>
              </li>
              <li>
                <p>Wireframing & prototyping</p>
              </li>
              <li>
                <p>Visual design & branding</p>
              </li>
              <li>
                {" "}
                <p>Interaction design </p>
              </li>

              <li>
                <p>Usability testing</p>
              </li>
            </ul>
          </WebLanguages>
          <UXImage src={uxicon} alt="image" />
        </ImageContainer>
      </Mobiledevelopement>
    </>
  )
}
