import React from "react"
import styled from "styled-components"
import comingsoon from "../assets/videos/Comingsoon.mp4"
import comingsoonmobile from "../assets/videos/ComingsoonMobile.mp4"

const Backgroundcolor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8f56c0 !important;
  @media screen and (max-width: 600px) {
  }
`

const ComingSoonVideo = styled.video`
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const ComingSoonVideoMobile = styled.video`
  display: none;
  @media screen and (max-width: 600px) {
    display: Block;
  }
`
export default function Blogs() {
  return (
    <>
      <Backgroundcolor>
        <ComingSoonVideo loop autoplay="" muted>
          <source src={comingsoon} type="video/mp4" />
          Your browser does not support the video tag.
        </ComingSoonVideo>
        <ComingSoonVideoMobile loop autoplay="" muted>
          <source src={comingsoonmobile} type="video/mp4" />
          Your browser does not support the video tag.
        </ComingSoonVideoMobile>
      </Backgroundcolor>
    </>
  )
}
