import React from "react"
import logo from "../assets/images/Infidos.png"
import styled from "styled-components"
import { Link } from "react-router-dom"

const NavRoot = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  background-color: #344781;

  margin-top: 20px;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`

const NavSubRoot = styled.div`
  display: flex;
  align-items: center;
`
const LogoImg = styled.img`
  border-radius: 50%;
  margin: 20px 0px 0px 100px;
`

const NavigationLinks = styled(Link)`
  text-decoration: none;
  margin-left: 60px;
  margin-top: 30px;
  color: rgb(255, 255, 255);
  font-size: 16px;
`

const BtnGetstarted = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 16px;
  color: white;
  background-color: #f67280;
  width: 250px;
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 100px;
  margin-left: 84px; /* Adjust margin to center horizontally */
  border-radius: 40px;
  cursor: pointer;
  transition:
    background-color 10s ease,
    box-shadow 0.3s ease;

  /* Glassy effect */

  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
    animation: shine 5s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`

export default function Navigation({
  name,
  path,
  name2,
  path2,
  name3,
  path3,
  name4,
  path4,
}) {
  return (
    <>
      <NavRoot>
        <NavSubRoot>
          <LogoImg src={logo} alt="logo" width="100" height="100" />
          <NavigationLinks to={path}>{name}</NavigationLinks>
          <NavigationLinks to={path2}>{name2}</NavigationLinks>
          <NavigationLinks to={path3}>{name3}</NavigationLinks>
          <NavigationLinks to={path4}>{name4}</NavigationLinks>
        </NavSubRoot>
        <BtnGetstarted to="*"> Discuss Project</BtnGetstarted>
      </NavRoot>
    </>
  )
}
