import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import Mobiledev from "../assets/images/Mobiledesign.png"
import Mobileph from "../assets/images/Mobile.png"
const Backbtn = styled.button`
text-align:center;
text-decoration:none;
font-size:20px;
font-weight:2000;
padding: 10px;
margin-top:20px;
margin-right:40px;
border-radius:50%;
  color: white;
  background-color: #f67280;
  border: none;
  width: 50px;
  height:50px;

  // border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

      Glassy effect */
  border: 3px solid #fafafa; /* Border similar to the 'a' tag */
  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

 &:before {
    animation: shine 0.75s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }   

  @media screen and (max-width: 600px) {
    width: 14%;
  margin:20px;
  }
`
const BtnRoot = styled.div`
  display: flex;
  justify-content: end;
  background-color: #2c3e76;
`
const Mobiledevelopement = styled.div`
  width: 100%;
  padding-top: 100px;
  background-color: #2c3e76;
`

const MobileSubdevelopement = styled.div`
  width: 78%;
  display: flex;
  background-color: #2c3e76;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`
const MobileSubdevelopement2 = styled.div`
  width: 100%;
  display: flex;
  background-color: #2c3e76;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`
const Contentcontainer = styled.div`
  background-color: #2c3e76;

  color: white;
  width: 90%;

  & p {
    width: 90%;
    font-size: 18px;
    padding-bottom: 5px;
    background-color: #2c3e76;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
      width: 88%;
      font-size: 18px;
      padding-bottom: 5px;
      background-color: #2c3e76;
    }
  }
`
const Contentcontainer2 = styled.div`
  background-color: #2c3e76;
  color: white;
  width: 42%;

  & p {
    width: 90%;
    font-size: 18px;
    padding-bottom: 5px;
    background-color: #2c3e76;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
      width: 88%;
      font-size: 18px;
      padding-bottom: 5px;
      background-color: #2c3e76;
    }
  }
`
const MobiledevTitle = styled.h1`
  background-color: #2c3e76;
  padding-bottom: 30px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
`
const MobiledevTitle1 = styled.h2`
  background-color: #2c3e76;
  padding-bottom: 30px;
  padding-top: 30px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    width: 88%;
  }
`
const MobiledevTitle2 = styled.h2`
  background-color: #2c3e76;
  padding-bottom: 30px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    width: 88%;
  }
`

const MobileImg = styled.img`
  width: 54%;
  height: 16%;
  border-radius: 40px;
  @media screen and (max-width: 600px) {
    width: 150%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
`
const MobileImg2 = styled.img`
  width: 26%;
  background-color: #2c3e76;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const MobileImgmob = styled.img`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    width: 66%;
    background-color: #2c3e76;
  }
`

const Imgcontainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #2c3e76;
  width: 50%;
  gap: 80px;
  @media screen and (max-width: 600px) {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 20px;
  }
`
export default function MobileDevelopement() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBackClick = () => {
    navigate("/services")
  }

  const isServicesPage = location.pathname === "/services"
  return (
    <>
      {!isServicesPage && (
        <BtnRoot>
          <Backbtn onClick={handleBackClick}>&#8592; </Backbtn>
        </BtnRoot>
      )}
      <Mobiledevelopement>
        <MobileSubdevelopement>
          <Contentcontainer>
            <MobiledevTitle>Mobile Development</MobiledevTitle>
            <MobileImgmob src={Mobileph} />

            <p>
              In today's mobile-first world, we build intuitive native and
              cross-platform mobile apps
            </p>
            <p>to help you engage users and grow your business.</p>
            <p>Native App Development:</p>
          </Contentcontainer>
        </MobileSubdevelopement>
        <MobileSubdevelopement2>
          <Contentcontainer2>
            <MobiledevTitle1>Android</MobiledevTitle1>
            <MobiledevTitle2>Mobile frame Works:</MobiledevTitle2>
            <p>React Native & Expo</p>
            <p>Flutter</p>
            <p>Ecommerce, Food delivery app etc...</p>
          </Contentcontainer2>
          <Imgcontainer>
            <MobileImg src={Mobiledev} />
            <MobileImg2 src={Mobileph} />
          </Imgcontainer>
        </MobileSubdevelopement2>
      </Mobiledevelopement>
    </>
  )
}
