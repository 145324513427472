import React, { useState } from "react"
import Navigation from "../components/Navigation"
import styled from "styled-components"
import logo from "../assets/images/Infidos.png"
import mainContent1 from "../assets/images/7.png"
import expert from "../assets/images/expert_developer.png"
import InnovativeDesigners from "../assets/images/Innovative_desingers.png"
import ExpertedDev from "../assets/images/customized.png"
import Scalable from "../assets/images/scalble.png"
import whyus from "../assets/images/Why_us.png"
import Blubimg from "../assets/images/blub.png"
import Expertimage from "../assets/images/expert_dev.png"
import Designer from "../assets/images/Designer.png"
import DesignerBase from "../assets/images/Desingner_base.png"
import CustomImg from "../assets/images/Cutomize_.png"
import ourimg from "../assets/images/ourservices.png"
import servicemobile from "../assets/images/service_Mobile.png"
import image from "../assets/images/1.png"
// import indlang from "../assets/images/Indlanglogo.png"
// import blinkport from "../assets/images/Teleport (9).png"
import Balavignesh from "../assets/images/Balavignesh.png"
import Narasimhan from "../assets/images/Narasimhan.png"
// import Freecard from "../assets/videos/content1.mp4"
import BackgroundNa from "../assets/videos/Balavignesh.mp4"
import BackgroundBa from "../assets/videos/Balavignesh_Back.mp4"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"

const NavDesktop = styled.div`
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const MobileNavWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const MobileCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: -1;
`

const NavigationMobile = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    padding: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

const MobileNavigationLinks = styled(Link)`
  display: none;

  @media screen and (max-width: 600px) {
    border-radius: 10%;
    width: 70%;
    position: relative; /* Ensure the pseudo-element is positioned relative to the card */
    overflow: hidden; /* Hide the shine effect overflow */
  }
  @media screen and (max-width: 600px) {
    display: block;
    text-decoration: none;
    color: rgb(255, 255, 255);
    gap: 70px;
    font-size: 20px;
    padding: 8px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 24px;
    width: 400%;

    background-color: #f67280;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

  &:hover:before {
    animation: shine 5s ease-in-out;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }
`
const CloseButton = styled.button`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;

    position: absolute;
    top: 100px;
    right: 50px;
    background-color: white;
    border-radius: 80%;
    color: red;
    border: none;
    width: 24px;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #007bff;
    }
  }
`

const ImageContainer = styled.div`
  display: none;
  width: 100%;
  @media screen and (max-width: 600px) {
    display: flex;
    margin-top: 30px;
  }
`
const MobNavRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.img`
  border-radius: 50%;
  margin: 20px 0px 0px 100px;

  @media screen and (max-width: 600px) {
    border-radius: 50%;
    margin: 20px 0 0 50px;
    width: 80px;
    height: 80px;
  }
`

const LadderBtn = styled.a`
  display: none;
  @media screen and (max-width: 600px) {
    border-radius: 50%;
    margin: 50px 30px 0 0px;
    height: 80px;
    display: flex;
    font-size: 28px;
    color: white;
  }
`

// const CardContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;

// `;

//  Home

const Header = styled.header`
  width: 100%;
  display: flex;
  margin-top: 100px;
  @media screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
  }
`
const HeaderContentRoot = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: center;
  margin-left: 96px;
  gap: 30px;
  @media screen and (max-width: 600px) {
    width: 88%;
    margin-left: 30px;
    gap: 20px;
  }
`
const HeaderTitle = styled.h2`
  width: 90%;
  font-size: 46px;
  @media screen and (max-width: 600px) {
    font-size: 26px;
    width: 98%;
  }
`

const HeaderPara = styled.p`
  width: 60%;
  font-size: 16px;
  @media screen and (max-width: 600px) {
    width: 90%;
    font-size: 18px;
  }
`
const HeaderImage = styled.img`
  @media screen and (max-width: 600px) {
    margin-top: 40px;
    width: 100%;
  }
`

// const BtnGetstartedDesktop = styled.button`
//   padding: 16px;
//   color: white;
//   background-color: #f67280;
//   border: none;
//   margin-bottom: 50px;
//   width: 350px;
//   margin-left: 84px; /* Adjust margin to center horizontally */
//   border-radius: 24px;
//   cursor: pointer;
//   transition: background-color 0.3s ease, box-shadow 0.3s ease;

//     Glassy effect */
//   border: 3px solid #fafafa; /* Border similar to the 'a' tag */
//   position: relative; /* Ensure the pseudo-element is positioned relative to the card */
//   overflow: hidden; /* Hide the shine effect overflow */

//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: -75%;
//     width: 50%;
//     height: 100%;
//     background: rgba(255, 255, 255, 0.5);
//     transform: skewX(-25deg);
//   }

//   &:before {
//     animation: shine 0.75s ease-in-out infinite;
//   }

//   @keyframes shine {
//     0% {
//       left: -75%;
//     }
//     100% {
//       left: 125%;
//     }
//   }

//   &:hover {
//     background-color: #e5576f;
//     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
//   }

//   @media screen and (max-width: 600px) {
//   display:none;
//   }
// `

const BtnGetstartedDesktop = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 16px;
  color: white;
  background-color: #f67280;
  margin-bottom: 50px;
  width: 350px;
  margin-left: 84px; /* Adjust margin to center horizontally */
  border-radius: 4px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  /* Glassy effect */

  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
    animation: shine 5s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const BtnGetstartedMobile = styled(Link)`
  display: none;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 16px;
  color: white;
  background-color: #f67280;
  margin-bottom: 50px;
  width: 350px;
  margin-left: 84px; /* Adjust margin to center horizontally */
  border-radius: 4px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  /*Glassy effect */
  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

  &:before {
    animation: shine 5s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 600px) {
    display: block;
    width: 90%;
    margin: 0;
    margin-bottom: 50px;
  }
`
const WhyUsRoot = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #d9f381;
  @media screen and (max-width: 600px) {
    height: 100%;
  }
`
const WhyUsSubRoot = styled.div`
  width: 100%;
  height: 100%;
  background-color: #d9f381;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 50px;
  }
`
const WhyUsContent1 = styled.div`
  width: 34%;
  height: 76%;
  background-color: white;
  border-radius: 8%;

  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }

  @media screen and (max-width: 600px) {
    margin: 40px;
    width: 80%;
    height: 100%;
    border-radius: 16px;
  }
`

const KeyPoints = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  width: 90%;
  margin: 26px;

  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }

  @media screen and (max-width: 600px) {
    width: 84%;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
`
const KeyImage = styled.img`
  @media screen and (max-width: 600px) {
    width: 40%;
    margin-top: 20px;
  }
`

const KeyTitle = styled.p`
  background-color: white;
  text-align: center;
  width: 60%;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    width: 90%;
    margin-top: 14px;
  }
`

const KeyImage2 = styled.img`
  @media screen and (max-width: 600px) {
    width: 80%;
  }
`

const KeyPoints2 = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8%;
  height: 100%;

  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }
`

const KeyTitle2 = styled.h2`
  background-color: white;
  text-align: center;
  width: 60%;
  @media screen and (max-width: 600px) {
    font-size: 26px;
    width: 70%;
    margin-top: 25px;
  }
`

const WhyUsContent2 = styled.div`
  width: 30%;
  height: 85%;
  background-color: white;
  border-radius: 8%;
  @media screen and (max-width: 600px) {
    width: 80%;
    height: 100%;
    margin-top: 60px;
    padding: 50px 50px;
  }
`

const WhyUsMainContentRoot = styled.div`
  width: 100%;
  height: 100%;
`

const ExpertDevloperContent = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`
const BlubImg = styled.img`
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const DesignerImage = styled.img`
  border-radius: 50%;
  width: 85%;
  margin-top: 70px;
  margin-right: 50px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`
const ExpertSideContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
`

const ExpertPara = styled.p`
  color: white;
  width: 70%;
  font-size: 19px;
  @media screen and (max-width: 600px) {
    width: 90%;
    font-size: 16px;
  }
`
const ExpertTitle = styled.h1`
  margin-bottom: 20px;
  color: white;
  width: 70%;
  text-align: left;
  @media screen and (max-width: 600px) {
    width: 90%;
    font-size: 26px;
    text-align: center;
    margin-bottom: 30px;
  }
`
const ExpertImage = styled.img`
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 88%;
  }
`

const UxImage = styled.img`
  width: 60%;
  @media screen and (max-width: 600px) {
    width: 88%;
    margin-right: 20px;
  }
`
const CustomizedImage = styled.img`
  width: 40%;
  margin: 50px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const CustomizedImageMobile = styled.img`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    width: 80%;
    margin: 0px;
  }
`
const OurServices = styled.img`
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const OurServicesMobile = styled.img`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`

const OurServicesRoot = styled.div`
  width: 100%;
  background-color: #d9f381;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BtnServices = styled(Link)`
  text-align: center;
  text-decoration: none;
  padding: 16px;
  color: white;
  background-color: #f67280;
  border: none;
  margin-bottom: 50px;
  width: 450px;

  border-radius: 30px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  /* Glassy effect */

  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

  &:before {
    animation: shine 5s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 600px) {
    width: 90%;
    margin: 20px;
    margin-bottom: 50px;
  }
`

// const FlipCardRoot = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width:100%;
//   height: 700px;;
//   margin-top:70px;
// margin-bottom:70px;
//    @media screen and (max-width: 600px) {
// width:100%;
// height:100vh;
// margin-top:200px;
// margin-bottom:300px;

// `;
const OurProductsTitle = styled.h2`
  color: white;
  margin-top: 170px;
  margin-bottom: 70px;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 600px) {
    margin-top: 70px;
    margin-bottom: 70px;
  }
`
const ProductLink = styled(Link)``
// const FlipCardSubRoot = styled.div`
//   width: 100%;
//   height:120%;
//   background-color: e5576f;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 200px;

//    @media screen and (max-width: 600px) {
//   display: flex;
//   flex-direction: column;

//     gap: 50px;
// `;

// const FlipCard = styled.div`
//   background-color: transparent;
//   width: 300px;
//   height: 300px;
//   perspective: 1000px;
// `;

// const FlipCardInner = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   transition: transform 0.8s ease-in-out;
//   transform-style: preserve-3d;

//   &:hover {
//     transform: rotateY(180deg);
//   }
// `;

// const FlipCardFront = styled.div`
//   position: absolute;
//   width: 120%;
//   height: 120%;
//   backface-visibility: hidden;
//   background-color: #bbb;
//   color: black;
//   border-radius: 20px;
//    @media screen and (max-width: 600px) {
//   width: 80%;
//   height: 80%;

// `;

// const FlipCardBack = styled.div`
//   position: absolute;
//   width: 120%;
//   height: 120%;
//   backface-visibility: hidden;
//   background-color: dodgerblue;
//   color: white;
//   transform: rotateY(180deg);
//   border-radius: 20px;

//         Glassy effect */
//   border: 3px solid #fafafa; /* Border similar to the 'a' tag */
//   position: relative; /* Ensure the pseudo-element is positioned relative to the card */
//   overflow: hidden; /* Hide the shine effect overflow */

//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: -75%;
//     width: 50%;
//     height: 100%;
//     background: rgba(255, 255, 255, 0.5);
//     transform: skewX(-25deg);
//   }

//   &:hover:before {
//     animation: shine 0.75s ease-in-out;
//   }

//   @keyframes shine {
//     0% {
//       left: -75%;
//     }
//     100% {
//       left: 125%;
//     }
//   }

//   &:hover {
//     background-color: #e5576f;

//   }

//      @media screen and (max-width: 600px) {
//   width: 90%;
//   height: 90%;

// `;

// const FlipCardBackTeam = styled.div`
//   position: absolute;
//   width: 120%;
//   height: 120%;
//   backface-visibility: hidden;
//   background-color: dodgerblue;
//   color: white;
//   transform: rotateY(180deg);
//   border-radius: 20px;

//         Glassy effect */
//   border: 3px solid #fafafa; /* Border similar to the 'a' tag */
//   position: relative; /* Ensure the pseudo-element is positioned relative to the card */
//   overflow: hidden; /* Hide the shine effect overflow */

//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: -75%;
//     width: 50%;
//     height: 100%;
//     background: rgba(255, 255, 255, 0.5);
//     transform: skewX(-25deg);
//   }

//   &:hover:before {
//     animation: shine 0.75s ease-in-out;
//   }

//   @keyframes shine {
//     0% {
//       left: -75%;
//     }
//     100% {
//       left: 125%;
//     }
//   }

//   &:hover {
//     background-color: #e5576f;

//   }

//      @media screen and (max-width: 600px) {
//   width: 95%;
//   height: 95%;
//     @keyframes shine {
//     0% {
//       left: -75%;
//     }
//     100% {
//       left: 175%;
//     }
//   }

// `;

const FlipCardImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

// const FlipCardImageTeam = styled.img`
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//        @media screen and (max-width: 600px) {
//         background-color:none;
// `;
// const FlipbackCardImage = styled.img`
//   width: 50%;
//   height: 50%;
//   margin-bottom: 20px;
//   border-radius: 50%;
//    @media screen and (max-width: 600px) {
//         background-color:none;
// `;
// const FlipCardFrontTeam = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   backface-visibility: hidden;
//   background-color: none;
//   color: black;
//   border-radius: 20px;
//    @media screen and (max-width: 600px) {
//   width: 100%;
//   height: 100%;

// `;

const FlipCardVideoContent = styled.video`
  width: 100%;
`
// const FlipCardTeamContent = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   background-color: #d9f381;

//   & p {
//     background-color: #d9f381;
//     color: black;
//     margin: 7px;
//     font-size: 14px;
//   }
//   & h1 {
//     background-color: #d9f381;
//     color: black;
//     font-size: 22px;
//     margin-top: 20px;

//   }

//    @media screen and (max-width: 600px) {
//  width:100%;
//   height:300px;
// `;
// const OurTeamRoot = styled.div`
// `;

const GlobalStyle = styled.div`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  display: flex;
  align-items: center;
  //  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
`

// Styled Components
const Card = styled.div`
  position: relative;

  height: 350px;
  width: 350px;
  perspective: 200rem;
  margin: 2rem;
  background-color: none;

  &:hover .card__side--back {
    transform: rotateY(0);
  }

  &:hover .card__side--front {
    transform: rotateY(-180deg);
  }

  @media screen and (max-width: 600px) {
    width: 80%;
    height: 100%;
  }
`

const CardSide = styled.div`
  transition: all 0.8s ease;
  background-color: #344781;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
`

const CardSideFront = styled(CardSide)`
  background-color: none;
`

const CardSideBack = styled(CardSide)`
  transform: rotateY(180deg);
  border-radius: 40px;
  background-color: dodgerblue;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

  &:hover:before {
    animation: shine 5s ease-in-out;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
  }
`
const ClickMe = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

export default function Home({
  path,
  name,
  path2,
  name2,
  path3,
  name3,
  path4,
  name4,
}) {
  const [mobileNavVisible, setMobileNavVisible] = useState(false)

  const toggleMobileNavVisibility = () => {
    setMobileNavVisible(!mobileNavVisible)
  }

  const handleCloseButtonClick = () => {
    setMobileNavVisible(false)
  }
  return (
    <>
      {!mobileNavVisible && (
        <>
          <NavDesktop>
            <Navigation
              name={"Home"}
              path={"/"}
              name2={"Services"}
              path2={"/services"}
              name3={"Products"}
              path3={"/products"}
              name4={"Blogs"}
              path4={"/blog"}
            />
          </NavDesktop>
          <ImageContainer>
            <MobNavRoot>
              <Logo src={logo} alt="logo" width="100" height="100" />
              <LadderBtn onClick={toggleMobileNavVisibility}>
                <i className="fa-solid fa-bars larger-icon fa-shake"></i>
              </LadderBtn>
            </MobNavRoot>

            {/* <CardContainer /> */}
          </ImageContainer>
          <Header>
            <HeaderContentRoot>
              <HeaderTitle>
                Revolutionize Your Business with Innovative Tech Solutions
              </HeaderTitle>
              <HeaderPara>
                Harness the power of cutting-edge technologies with our elite
                developers and designers to scale your business beyond expectations.
              </HeaderPara>
            </HeaderContentRoot>

            <HeaderImage src={mainContent1} alt="img" width="500px" />
            <BtnGetstartedMobile to={path4}>
              Launch Your Tech Transformation
            </BtnGetstartedMobile>
          </Header>
          <BtnGetstartedDesktop to="*">
            Launch Your Tech Transformation
          </BtnGetstartedDesktop>

          <WhyUsRoot>
            <WhyUsSubRoot>
              <WhyUsContent2>
                <KeyPoints2>
                  <KeyTitle2> Why us?</KeyTitle2>
                  <KeyImage2 src={whyus} alt="Image" width="80%" />
                </KeyPoints2>
              </WhyUsContent2>
              <WhyUsContent1>
                <KeyPoints>
                  <KeyImage src={expert} alt="Image" width="20%" />
                  <KeyTitle>Expert Developers</KeyTitle>
                </KeyPoints>
                <KeyPoints>
                  <KeyImage src={InnovativeDesigners} alt="Image" width="20%" />
                  <KeyTitle>Innovative Designers</KeyTitle>
                </KeyPoints>
                <KeyPoints>
                  <KeyImage src={ExpertedDev} alt="Image" width="20%" />
                  <KeyTitle>Customized Solutions</KeyTitle>
                </KeyPoints>
                <KeyPoints>
                  <KeyImage src={Scalable} alt="Image" width="20%" />
                  <KeyTitle>Scalable Solutions</KeyTitle>
                </KeyPoints>
              </WhyUsContent1>
            </WhyUsSubRoot>
          </WhyUsRoot>

          <WhyUsMainContentRoot>
            <ExpertDevloperContent>
              <BlubImg src={Blubimg} alt="Bulbimg" width="38%" height="38%" />
              <ExpertSideContentRoot>
                <ExpertTitle>Expert Developers</ExpertTitle>
                <ExpertPara>
                  Our team of developers is proficient in a wide range of modern
                  programming languages and frameworks. Whether you need a robust web
                  application, a scalable backend system, or a dynamic mobile app, we
                  have the expertise to deliver.
                </ExpertPara>
                <ExpertImage src={Expertimage} alt="Bulbimg" />
              </ExpertSideContentRoot>
            </ExpertDevloperContent>
            <ExpertDevloperContent>
              <ExpertSideContentRoot>
                <ExpertTitle>Innovative Designers</ExpertTitle>
                <ExpertPara>
                  Our designers are not only creative but also deeply understand user
                  experience (UX) and user interface (UI) design principles. They
                  create visually stunning and user-friendly designs that captivate
                  your audience and enhance user engagement.
                </ExpertPara>
                <UxImage src={DesignerBase} alt="Bulbimg" />
              </ExpertSideContentRoot>
              <DesignerImage src={Designer} alt="Bulbimg" width="38%" height="38%" />
            </ExpertDevloperContent>

            <ExpertDevloperContent>
              <CustomizedImage
                src={CustomImg}
                alt="Bulbimg"
                width="38%"
                height="38%"
              />
              <ExpertSideContentRoot>
                <ExpertTitle>Customized Solutions</ExpertTitle>
                <ExpertPara>
                  We understand that every business is unique. Our approach is
                  tailored to meet your specific needs and objectives. We work
                  closely with you to understand your goals and develop custom
                  solutions that drive growth and success.
                </ExpertPara>

                <CustomizedImageMobile
                  src={CustomImg}
                  alt="Bulbimg"
                  width="38%"
                  height="38%"
                />
              </ExpertSideContentRoot>
            </ExpertDevloperContent>
          </WhyUsMainContentRoot>
          <OurServicesRoot>
            <OurServices src={ourimg} alt="Bulbimg" width="100%" height="100%" />
            <OurServicesMobile
              src={servicemobile}
              alt="Bulbimg"
              width="100%"
              height="100%"
            />
            <BtnServices to="/services">Go to the page</BtnServices>
          </OurServicesRoot>

          {/* <FlipCardRoot>
            <OurProductsTitle>Our Products</OurProductsTitle>

            <FlipCardSubRoot>
              <FlipCard>
                <FlipCardInner>
                  <FlipCardFront>
                    <FlipCardImage src={image} alt="Avatar" />
                  </FlipCardFront>
                  <FlipCardBack>
                    <FlipCardVideoContent loop autoplay="" muted>
                      <source src={Freecard} type="video/mp4" />
                      Your browser does not support the video tag.
                    </FlipCardVideoContent>
                  </FlipCardBack>
                </FlipCardInner>
              </FlipCard>

              <FlipCard>
                <FlipCardInner>
                  <FlipCardFront>
                    <FlipCardImage src={indlang} alt="Avatar" />
                  </FlipCardFront>
                  <FlipCardBack>
                    <FlipCardVideoContent loop autoplay="" muted>
                      <source src={Freecard} type="video/mp4" />
                      Your browser does not support the video tag.
                    </FlipCardVideoContent>
                  </FlipCardBack>
                </FlipCardInner>
              </FlipCard>
            </FlipCardSubRoot>
          </FlipCardRoot>

          <OurTeamRoot>
            <FlipCardRoot>
              <OurProductsTitle>Our Team</OurProductsTitle>

              <FlipCardSubRoot>
                <FlipCard>
                  <FlipCardInner>
                    <FlipCardFrontTeam>
                      <FlipCardImageTeam src={Balavignesh} alt="Avatar" />
                    </FlipCardFrontTeam>
                    <FlipCardBackTeam>
                      <FlipCardTeamContent>
                        <FlipbackCardImage src={Balavignesh} alt="Avatar" />
                        <h1>Balavignesh M</h1>
                        <p>Founder & Full Stack Developer</p>
                      </FlipCardTeamContent>
                    </FlipCardBackTeam>
                  </FlipCardInner>
                </FlipCard>

                <FlipCard>
                  <FlipCardInner>
                    <FlipCardFrontTeam>
                      <FlipCardImageTeam src={Narasimhan} alt="Avatar" />
                    </FlipCardFrontTeam>
                    <FlipCardBackTeam>
                      <FlipCardTeamContent>
                        <FlipbackCardImage src={Narasimhan} alt="Avatar" />
                        <h1>Narasimhan R</h1>
                        <p>UI/UX Designer & Web developer</p>
                      </FlipCardTeamContent>
                    </FlipCardBackTeam>
                  </FlipCardInner>
                </FlipCard>
              </FlipCardSubRoot>
            </FlipCardRoot>
          </OurTeamRoot> */}

          <OurProductsTitle>Our Products</OurProductsTitle>

          <GlobalStyle>
            <Card>
              <ProductLink to="/products">
                <FlipCardImage src={image} alt="Avatar" />
              </ProductLink>
            </Card>

            {/* <Card>
              <CardSideFront className="card__side card__side--front">
                <FlipCardImage src={blinkport} alt="Avatar" />
              </CardSideFront>
              <CardSideBack className="card__side card__side--back">
                <FlipCardVideoContent loop autoplay="" muted>
                  <source src={Freecard} type="video/mp4" />
                  Your browser does not support the video tag.
                </FlipCardVideoContent>
              </CardSideBack>
            </Card> */}
            {/* <ClickMe>
              <p
                style={{
                  color: "white",
                  backgroundColor: "transparent",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: 200,
                  animation: "exampleAnimation 1s infinite",
                  marginLeft: "95px",
                }}
              >
                Click me
              </p> */}
              {/* <p
                style={{
                  color: "white",
                  backgroundColor: "transparent",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: 200,
                  animation: "exampleAnimation 1s infinite",
                }}
              >
                Hover me
              </p> */}
            {/* </ClickMe> */}
            {/* <Card>
              <CardSideFront className="card__side card__side--front">
                <FlipCardImage src={indlang} alt="Avatar" />
              </CardSideFront>
              <CardSideBack className="card__side card__side--back">
                <FlipCardVideoContent loop autoplay="" muted>
                  <source src={Freecard} type="video/mp4" />
                  Your browser does not support the video tag.
                </FlipCardVideoContent>
              </CardSideBack>
            </Card> */}
          </GlobalStyle>

          <OurProductsTitle>Our Team</OurProductsTitle>
          <GlobalStyle>
            <Card>
              <CardSideFront className="card__side card__side--front">
                <FlipCardImage src={Balavignesh} alt="Avatar" />
              </CardSideFront>
              <CardSideBack
                className="card__side 
                card__side--back"
              >
                <FlipCardVideoContent loop autoplay="" muted>
                  <source src={BackgroundBa} type="video/mp4" />
                  Your browser does not support the video tag.
                </FlipCardVideoContent>
              </CardSideBack>
            </Card>

            <Card>
              <CardSideFront className="card__side card__side--front">
                <FlipCardImage src={Narasimhan} alt="Avatar" />
              </CardSideFront>
              <CardSideBack className="card__side card__side--back">
                <FlipCardVideoContent loop autoplay="" muted>
                  <source src={BackgroundNa} type="video/mp4" />
                  Your browser does not support the video tag.
                </FlipCardVideoContent>
              </CardSideBack>
            </Card>
          </GlobalStyle>

          <Footer />
        </>
      )}

      {mobileNavVisible && (
        <MobileNavWrapper>
          <CloseButton onClick={handleCloseButtonClick}>X</CloseButton>
          <MobileCard />
          <NavigationMobile>
            <MobileNavigationLinks to={path}>{name}</MobileNavigationLinks>
            <MobileNavigationLinks to={path2}>{name2}</MobileNavigationLinks>
            <MobileNavigationLinks to={path3}>{name3}</MobileNavigationLinks>
            <MobileNavigationLinks to={path4}>{name4}</MobileNavigationLinks>
            <MobileNavigationLinks to="*">Discuss Project</MobileNavigationLinks>
          </NavigationMobile>
        </MobileNavWrapper>
      )}
    </>
  )
}
