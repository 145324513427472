import React from "react"
import styled from "styled-components"
// import Webdev from "../assets/images/first.png"
// import ErpContent4 from "../assets/images/ERP_Content (4).png"
// import Webdevelop from "../assets/images/webdevelop.png"
// import webcontent2 from "../assets/images/43.png"
import { useLocation, useNavigate } from "react-router-dom"
import python from "../assets/images/python.png"
import pythonuses from "../assets/images/pythonuses.png"
import tech from "../assets/images/Technology.png"
// import webcontent3 from "../assets/images/44.png"
// import webcontent4 from "../assets/images/45.png"
// import webcontent5 from "../assets/images/46.png"
// import webcontent6 from "../assets/images/47.png"
import Webdevelop from "../assets/images/webdevelop.png"
import Webdev from "../assets/images/service page.png"
import pythonuse from "../assets/images/PythonUse.png"
import CMS from "../assets/images/CMS.png"

const Backbtn = styled.button`
text-align:center;
text-decoration:none;
font-size:20px;
font-weight:2000;
padding: 10px;
margin-top:20px;
margin-right:40px;

  color: white;
  background-color: #f67280;
  border: none;
  width: 50px;
  height:50px;

  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

      Glassy effect */
  border: 3px solid #fafafa; /* Border similar to the 'a' tag */
  position: relative; /* Ensure the pseudo-element is positioned relative to the card */
  overflow: hidden; /* Hide the shine effect overflow */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-25deg);
  }

 &:before {
    animation: shine 0.75s ease-in-out infinite;
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%;
    }
  }

  &:hover {
    background-color: #e5576f;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }   

  @media screen and (max-width: 600px) {
    width: 14%;
  margin:20px;
  }
`
const BtnRoot = styled.div`
  display: flex;
  justify-content: end;
  background-color: #2c3e76;
`
const ErpContent = styled.img`
  width: 100%;
  background-color: #2c3e76;

  @media screen and (max-width: 600px) {
    display: none;
  }
`
const WebdevelopementRoot = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2c3e76;
  }
`
const WebdevelopementRoot1 = styled.div`
  color: white;
  display: flex;
  font-size: 18px;
  background-color: #2c3e76;
  width: 100%;
  align-items: center;
  justfy-content: center;
  padding-bottom: 30px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2c3e76;
  }
`
const WebSubroot = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
  justify-content: center;

  background-color: #2c3e76;

  @media screen and (max-width: 600px) {
    display: flex;
    width: 96%;
    flex-direction: column;
    align-items: center;
    background-color: #2c3e76;
  }
`
const WebSubroot2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  justify-content: center;
  background-color: #2c3e76;
  padding-top: 50px;

  @media screen and (max-width: 600px) {
    display: flex;
    width: 96%;
    flex-direction: column;
    align-items: center;
    background-color: #2c3e76;
  }
`
const Img = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  background-color: #2c3e76;
`

const Img1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #2c3e76;
`

const WebdevelopeImg = styled.img`
  background-color: #2c3e76;
  width: 450px;
  height: 500px;
  @media screen and (max-width: 600px) {
    width: 60%;
    height: 60%;
    background-color: #2c3e76;
  }
`
const PythonImg = styled.img`
  width: 50%;
  height: 50%;
  background-color: #2c3e76;
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const PythonDescktopImg = styled.img`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    width: 50%;
    height: 50%;
    background-color: #2c3e76;
    padding-bottom: 30px;
  }
`
const PythonImg1 = styled.img`
  width: 40%;
  height: 40%;
  border-radius: 30px;
  background-color: #2c3e76;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    margin: 0;
    width: 90%;
    height: 40%;
  }
`
const PythonImg3 = styled.img`
  width: 40%;
  height: 40%;
  border-radius: 30px;
  background-color: #2c3e76;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    margin: 0;
    width: 40%;
    height: 40%;
  }
`
const PythonImg4 = styled.img`
  width: 50%;
  border-radius: 30px;
  background-color: #2c3e76;
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    margin: 0;
    width: 40%;
    height: 40%;
  }
`

const WebTitle = styled.h1`
  text-align: left;
  background-color: #2c3e76;
  padding-bottom: 20px;
  width: 90%;
  font-size: 24px;

  @media screen and (max-width: 600px) {
    width: 90%;
    padding-bottom: 20px;
    background-color: #2c3e76;
  }
`

const WebTitle2 = styled.h1`
  text-align: left;
  background-color: #2c3e76;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 90%;
  font-size: 24px;

  @media screen and (max-width: 600px) {
    width: 90%;
    padding-bottom: 20px;
    background-color: #2c3e76;
  }
`

const WebContent1 = styled.p`
  background-color: #2c3e76;
  font-size: 22px;
  width: 90%;

  @media screen and (max-width: 600px) {
    width: 90%;
    font-size: 15px;
    background-color: #2c3e76;
  }
`
const WebContent2 = styled.p`
  background-color: #2c3e76;
  font-size: 18px;
  width: 90%;

  @media screen and (max-width: 600px) {
    width: 90%;
    font-size: 15px;
    background-color: #2c3e76;
  }
`
const WebLanguages = styled.div`
  background-color: #2c3e76;
  width: 70%;
  display: flex;
  flex-direction: column;

  & p {
    background-color: #2c3e76;
    font-size: 20px;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    background-color: #2c3e76;

    padding-top: 50px;
    & p {
      background-color: #2c3e76;

      font-size: 15px;
    }
  }
`
const Allcontent = styled.div`
  background-color: #2c3e76;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default function Webdevelopement() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBackClick = () => {
    navigate("/services")
  }

  const isServicesPage = location.pathname === "/services"
  return (
    <>
      {!isServicesPage && (
        <BtnRoot>
          <Backbtn onClick={handleBackClick}>&#8592; </Backbtn>
        </BtnRoot>
      )}
      <WebdevelopementRoot>
        <WebSubroot>
          <WebTitle>Web Development</WebTitle>
          <WebContent1>
            Our seasoned web development team can architect and build robust,
            scalable web applications tailored to your business needs. We specialize
            in modern frameworks and technologies like:
          </WebContent1>

          <WebLanguages>
            <p>Front-end: React, Next.js</p>
            <p>Back-end: Node.js/Express.js, Python/Fast API/ Flask/Django. </p>
            <p>Databases: MongoDB, MySQL</p>
          </WebLanguages>
        </WebSubroot>
        <Img>
          <WebdevelopeImg src={Webdevelop} alt="erp" />
        </Img>
      </WebdevelopementRoot>

      <Allcontent>
        <ErpContent src={Webdev} alt="erp" />
        <WebdevelopementRoot1>
          <WebSubroot>
            <WebTitle>Our web development services encompass:</WebTitle>

            <WebLanguages>
              <ul>
                <li>
                  {" "}
                  <p> Custom web application development </p>
                </li>
                <li>
                  <p>Progressive Web Apps (PWAs) </p>
                </li>
                <li>
                  <p> eCommerce solutions</p>
                </li>
                <li>
                  <p>Content Management Systems (CMS) </p>
                </li>
                <li>
                  {" "}
                  <p> Third-party APIs & integrations </p>
                </li>

                <li>
                  <p>(decision making and analysis)</p>
                </li>
                <li>
                  {" "}
                  <p> Responsive & cross-browser compatibility</p>
                </li>
                <li>
                  <p> Fast predictive analytics web apps</p>
                </li>
              </ul>
            </WebLanguages>
          </WebSubroot>
          <Img>
            <WebdevelopeImg src={CMS} alt="erp" />
          </Img>
        </WebdevelopementRoot1>

        <WebdevelopementRoot1>
          <WebSubroot2>
            <WebTitle2>Python Web Frameworks</WebTitle2>
            <Img>
              <PythonDescktopImg src={python} alt="erp" />
            </Img>
            <WebContent2>
              Our team has extensive experience in building robust and scalable
              webapplications using modern Python frameworks:
            </WebContent2>
            <WebTitle2>FastAPI </WebTitle2>
            <WebContent2>
              We leverage FastAPI to develop high-performance, asynchronous APIs with
              automatic data validation and serialization. Its intuitive design and
              lightning-fast execution make it ideal for building efficient and
              production-ready APIs.
            </WebContent2>
            <WebTitle2>Flask</WebTitle2>
            <WebContent2>
              Flask's simplicity and flexibility allow us to rapidly develop web
              applications and APIs. We utilize Flask's modular design and rich
              ecosystem of extensions to build secure, scalable, and maintainable
              solutions.
            </WebContent2>
          </WebSubroot2>
          <Img>
            <PythonImg src={python} alt="erp" />
          </Img>
        </WebdevelopementRoot1>
        <Img1>
          <PythonImg1 src={pythonuse} alt="erp" />
        </Img1>

        <WebdevelopementRoot1>
          <WebSubroot2>
            <WebTitle2>Python Web Frameworks</WebTitle2>
            <WebContent2>
              Our team has extensive experience in building robust and scalable
              webapplications using modern Python frameworks:
            </WebContent2>
            <WebTitle2>FastAPI </WebTitle2>
            <WebContent2>
              We leverage FastAPI to develop high-performance, asynchronous APIs with
              automatic data validation and serialization. Its intuitive design and
              lightning-fast execution make it ideal for building efficient and
              production-ready APIs.
            </WebContent2>
            <WebTitle2>Flask</WebTitle2>
            <WebContent2>
              Flask's simplicity and flexibility allow us to rapidly develop web
              applications and APIs. We utilize Flask's modular design and rich
              ecosystem of extensions to build secure, scalable, and maintainable
              solutions.
            </WebContent2>
          </WebSubroot2>
          <Img>
            <PythonImg3 src={pythonuses} alt="erp" />
          </Img>
        </WebdevelopementRoot1>
        <WebdevelopementRoot1>
          <WebSubroot2>
            <WebTitle>Technology</WebTitle>
            <WebTitle2>Blockchain Technology:</WebTitle2>
            <WebContent2>
              Our team has hands-on experience in building decentralized applications
              (DApps) and solutions leveraging blockchain technology. We work with
              popular blockchain platforms like Ethereum, Hyperledger, and Corda. We
              understand the intricacies of blockchain architecture, P2P networks,
              and can develop secure, transparent, and auditable blockchain
              solutions.
            </WebContent2>
            <WebTitle2>Artificial Intelligence (AI): </WebTitle2>
            <WebContent2>
              Our AI experts build intelligent systems using machine learning, deep
              learning, and natural language processing techniques. We leverage
              cutting-edge AI frameworks like TensorFlow, PyTorch, Scikit-Learn and
              have experience deploying and scaling AI models on cloud platforms.
            </WebContent2>
            <WebTitle2>Vision AI:</WebTitle2>
            <WebContent2>
              Our computer vision capabilities enable us to build applications for
              image/video processing and analysis, Optical Character Recognition
              (OCR), face detection and recognition, Augmented Reality (AR)
              experiences, defect inspection in manufacturing, and autonomous
              vehicles and robotics. We utilize deep learning architectures like
              CNNs, RNNs along with OpenCV.
            </WebContent2>
            <WebTitle2>IPFS (InterPlanetary File System):</WebTitle2>
            <WebContent2>
              We are well-versed with the decentralized IPFS protocol for storing and
              sharing data. We build decentralized storage and content delivery
              applications, IPFS gateways and web interfaces, integrate with
              blockchain and Web3 applications. Our engineers understand peer-to-peer
              networking and cryptography concepts.
            </WebContent2>
          </WebSubroot2>
          <Img>
            <PythonImg4 src={tech} alt="erp" />
          </Img>
        </WebdevelopementRoot1>
        {/* <ErpContent src={webcontent} alt="erp" /> */}
        {/* <ErpContent src={webcontent2} alt="erp" /> */}
        {/* <ErpContent src={webcontent3} alt="erp" /> */}
        {/* <ErpContent src={webcontent4} alt="erp" /> */}
        {/* <ErpContent src={webcontent5} alt="erp" /> */}
      </Allcontent>
    </>
  )
}
