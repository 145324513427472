import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import Products from "./pages/Products"
import Services from "./pages/Services"
import Blogs from "./pages/Blogs"
import NotFound from "./pages/NotFound"
import Erp from "./services/Erp"
import Webdevelopement from "./services/Webdevlopement"
import Mobiledevelopement from "./services/MobileDevelopment"
import Ui from "./services/Ui"

function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              name="Home"
              path="/"
              name2="Products"
              path2="/products"
              name3="Services"
              path3="/services"
              name4="Blogs"
              path4="/blog"
              name5="NotFound"
              path5="*"
            />
          }
          exact
        ></Route>
        <Route
          path="/products"
          element={
            <Products
              name="Home"
              path="/"
              name2="Products"
              path2="/products"
              name3="Services"
              path3="/services"
              name4="Blogs"
              path4="/blog"
              name5="NotFound"
              path5="*"
            />
          }
          exact
        ></Route>
        <Route path="/blog" element={<Blogs />} exact></Route>
        <Route
          path="/services"
          element={
            <Services
              name="Home"
              path="/"
              name2="Products"
              path2="/products"
              name3="Services"
              path3="/services"
              name4="Blogs"
              path4="/blog"
              name5="NotFound"
              path5="*"
            />
          }
          exact
        ></Route>
        <Route path="services/erp" element={<Erp />} exact></Route>
        <Route
          path="services/webdevelopement"
          element={<Webdevelopement />}
          exact
        ></Route>
        <Route
          path="services/mobiledevelopement"
          element={<Mobiledevelopement />}
          exact
        ></Route>
        <Route path="services/ui" element={<Ui />} exact></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default App
